import React from "react"
import Layout from "../containers/layout/layout"
import SEO from '../components/seo'
import Header from '../containers/layout/header'
import TitleArea from '../containers/contact/title-area'
import BannerArea from '../containers/contact/banner-area'
import FormArea from '../containers/contact/form-area'
import Footer from '../containers/layout/footer'

const ContactPage = () => (
  <Layout>
    <SEO 
        title="Contact" 
        description="How to contact the spectrum house team"
        keywords={["contact", "autism", "anxiety", "adhd", "spectrum house", "mental health", "children", "assessment", "diagnosis", "help", "psychologist", "psychology", "phone", "email"]}
    /> 
    <Header/>
    <div className="main-content">
        <TitleArea/>
        <BannerArea/>
        <FormArea/>
    </div>
    <Footer/>
  </Layout>
)

export default ContactPage
 